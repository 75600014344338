<template>
    <div class="container m-t-30">
      <div v-if="isInitializing">
        <br>
        <div style="text-align:center">Estamos verificando tu correo electrónico de recuperación...</div><br>
        <div style = "margin:0px 48%!important" class="loading" >
          <unc-loader></unc-loader>
        </div>
      </div>
      <div v-else-if="showNotFound" class="row animated fadeIn">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="jumbotron text-center">
            <h2>Tu correo electrónico de recuperación no se ha podido confirmar.</h2>
            <p>El enlace utilizado caducó o no es válido</p>
          </div>
        </div>
      </div>
      <div v-else class="row animated fadeIn">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="jumbotron text-center">
          <h2>Correo Actualizado</h2>
          <p>Tu correo electrónico fue actualizado satisfactoriamente.</p>
          <p><ul style="font-size:16px;text-align:left;">
            Recomendaciones:
            <blockquote>
              <li>Si recordás tu contraseña, podés iniciar sesión haciendo click en "Iniciar sesión en MiUNC"</li>
              <li>¿No recordás tu contraseña? Para recuperarla hacé click en "Recuperar contraseña" y seguí el procedimiento indicado</li>
            </blockquote>
          </ul></p>
          <div class="btn-container">
            <a :href="configApp.urlMiUNC" target="_blank">
              <button class="btn btn-primary waves-effect">Iniciar Sesión en MiUNC</button>
            </a>
            <a :href="configApp.urlRestorePass" target="_blank">
              <button class="btn btn-primary waves-effect">Recuperar contraseña</button>
            </a>
          </div>
        </div>
      </div>
    </div>

    </div>
  </template>
  
  <script>
  import { HttpFRONT, swal } from "../../share.js";
  import { mapGetters } from "vuex";
  
  export default {
    data() {
      return {
        titulo: "CONFIRMAR CORREO DE RECUPERACIÓN",
        isInitializing: true,
        user: "",
        showNotFound: false,
        showAdmins: false,
        admins: {}
      };
    },
    props: ["token"],
    computed: {
      ...mapGetters([
        "configApp"
      ]),
      showOneColumn() {
        return window.screen.width < 400 ? true : false;
      }
    },
    mounted() {
      if (this.token) {
        this.getData();
      } else {
        this.showNotFound = true;
        this.isInitializing = false;
      }
    },
  
    methods: {
      getData() {
        let self = this;
        HttpFRONT.get(`confirmRecoveryEmail/${this.token}`)
          .then(response => {
              self.isInitializing = false;
              if (response.status === 200) {
                  self.showNotFound = false;
                  swal({title:  'Correo de recuperación actualizado', text: `Tu correo de recuperación fue actualizado satisfactoriamente.`, type: 'success' });

              } else {
                  self.showNotFound = true;
              }
          })
          .catch((error) => {
              let status = error.response.status;
              self.isInitializing = true;
              let message = (error.response && error.response.data) || "Servicio temporalmente no disponible";
  
              switch (status) {
                case 400:
                case 404:
                  self.isInitializing = false;
                  self.showNotFound = true;
                  swal({
                    title: "Algo salió mal",
                    text: message,
                    type: "warning"
                  })
                  break;
                case 409:
                  swal({
                      title: "Algo salió mal",
                      text: message,
                      type: "warning"
                    })
                  self.$router.push('/');
                  break;
                default:
                  swal({
                    title: "Algo salió mal",
                    text: message,
                    type: "warning"
                  }).then(function(){
                      self.$router.push('/');
                  });
                  break;
              }
          }
        )
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  @media only screen and (max-width: 40em) {
    thead th:not(:first-child) {
      display: none;
    }

    td,
    th {
      display: block;
    }
  
    td[data-th]:before {
      content: attr(data-th);
    }
  }
  
  p {
    margin-bottom: 0px;
  }
  
  .input-group .fg-line {
    padding: 0 0px;
  }
  </style>
  