<template>
  <div class="container m-t-30">
    <div v-if="isInitializing">
      <br>
      <div style="text-align:center">Estamos verificando tu correo electrónico de recuperación...</div><br>
      <div style = "margin:0px 48%!important" class="loading" >
        <unc-loader></unc-loader>
      </div>
    </div>

    <div v-else-if="showNotFound" class="row animated fadeIn">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="jumbotron text-center">
          <h2>Tu correo electrónico de recuperación no se ha podido confirmar.</h2>
          <p>El período de confirmación de este correo caducó. Iniciá nuevamente el proceso de creación de Usuario UNC.</p>
          <button @click.prevent="goRegister()" class="btn btn-primary waves-effect">Crear usuario</button>
        </div>
      </div>
    </div>

    <div v-else class="row animated fadeIn">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="jumbotron text-center" style="margin-bottom:10px!important; padding-bottom:25px!important; padding-top:10px!important;">
          <h3>Ahora necesitamos verificar tus datos personales y relación con la UNC.</h3>
          <p>A continuación deberás ponerte en contacto con tu dependencia enviando un correo con lo siguiente: 
             <br>
                <ul style="font-size:16px;text-align:left;">
                 <strong> Asunto: </strong>
                <blockquote> 
                  <li>Verificación de datos personales y relación con la UNC</li>
                </blockquote>
                <strong> Cuerpo de mensaje: </strong>
                <blockquote>
                  <li> Nombre y Apellido </li>
                  <li> Número y constancia de CUIL </li>
                </blockquote>
            </ul>
            <ul style="font-size:16px;text-align:left;">
              <blockquote>
                <li>Si tu rol es Estudiante/Egresado, debés contactar a la facultad en la que tenés usuario en Guaraní.</li>
                <li>Si tu rol es Docente/Nodocente/Contratado, debés adjuntar documentación que avale la relación con UNC (por ejemplo: Resolución).</li>
                <li>Si tu rol es Otro, comentá una breve descripción del porqué solicitas la creación del usuario.</li>
              </blockquote>
            </ul>
            
            Cuando tus datos se encuentren verificados recibirás un mail para elegir tu nombre de Usuario UNC.</p>
          <button class="btn btn-primary m-t-10 waves-effect" style="font-size:18px" @click.prevent="showAdmins=!showAdmins;getadmins()">¿A dónde me contacto?</button>
        </div>

        <div v-if="showAdmins" class="row" align="center"><!--showAdmins-->
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="alert alert-info" role="alert">
              <div class="my-listview">
                <div class="row my-listview-item th hidden-xs">
                  <div class="col-sm-6 col-xs-12">Dependencia</div>
                  <div class="col-sm-6 col-xs-12">Correo</div>
                </div>

                <div class="row my-listview-item" v-for="item in admins" :key="item.correo">
                  <div class="col-sm-6 col-xs-12">
                    <p
                      style="font-size:11px"
                      class="hidden-sm hidden-md hidden-lg hidden-xl"
                    >Dependencia: {{ item.dependencia ? item.dependencia : 'Sin datos' }}</p>
                    <p class="hidden-xs">{{ item.dependencia ? item.dependencia : 'Sin datos' }}</p>
                  </div>

                  <div class="col-sm-6 col-xs-12">
                    <p
                      style="font-size:12px"
                      class="hidden-sm hidden-md hidden-lg hidden-xl"
                    >Correo: {{ item.correo ? item.correo : 'Sin datos' }}</p>
                    <p class="hidden-xs">{{ item.correo ? item.correo : 'Sin datos' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { HttpFRONT, swal } from "../../share.js";

export default {
  data() {
    return {
      titulo: "CONFIRMAR EMAIL",
      isInitializing: true,
      user: "",
      showNotFound: false,
      showAdmins: false,
      admins: {}
    };
  },
  props: ["token"],
  computed: {
    showOneColumn() {
      return window.screen.width < 400 ? true : false;
    }
  },
  mounted() {
    if (this.token) {
      this.getData();
    } else {
      this.showNotFound = true;
      this.isInitializing = false;
    }
  },

  methods: {
    getData() {
      let self = this;
      HttpFRONT.get(`confirmemail/${this.token}`)
        .then(response => {
            self.isInitializing = false;
            if (response.status === 200) {
                self.showNotFound = false;
            } else {
                self.showNotFound = true;
            }
        })
        .catch((error) => {
            let status = error.response.status;
            self.isInitializing = true;
            let message = (error.response && error.response.data) || "Servicio temporalmente no disponible";

            switch (status) {
               case 404:
               self.isInitializing = false;
               self.showNotFound = true;   
               break;
               case 409:
               swal({
                  title: "Algo salió mal",
                  text: 'El período de cofirmación de este correo caducó.',
                  type: "warning"
                })
                self.$router.push('/');
               break;
              case 500:
                swal({
                  title: "Algo salió mal",
                  text: message,
                  type: "warning"
                })
                self.$router.push('/');
                break;
              default:
                swal({
                  title: "Algo salió mal",
                  text: message,
                  type: "warning"
                }).then(function(){
                    self.$router.push('/');
                });
                break;
            }
        })
        .finally(() => {
          //self.isInitializing = false;
        });
    },
    getadmins() {
      let self = this;
      if (self.showAdmins) {
        HttpFRONT.get(`dataadmins`)
          .then(response => {
            if (response.status === 200) {
              self.showAdmins = true;
              self.admins = response.data;
            } else {
              self.showAdmins = false;
            }
          })
          .catch(error => {
            let status = error.response.status;

            let message =
              (error.response && error.response.data) ||
              "Servicio temporalmente no disponible";

            switch (status) {
              case 401:
                swal({
                  title: "Acceso denegado",
                  text: "No posee permisos para acceder a este servicio.",
                  type: "warning"
                });
                break;
              case 500:
                swal({
                  title: "Algo salió mal",
                  text: message,
                  type: "warning"
                });
                break;
              default:
                swal({
                  title: "Datos no guardados",
                  text: message,
                  type: "warning"
                });
                break;
            }
          })
          .finally(() => {
            //self.isInitializing = false;
          });
      }
    },
    goRegister() {
      this.$router.push("/registro");
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 40em) {
  thead th:not(:first-child) {
    display: none;
  }

  thead th {
    content: "hola q tal";
  }

  td,
  th {
    display: block;
  }

  td[data-th]:before {
    content: attr(data-th);
  }
}

p {
  margin-bottom: 0px;
}

.input-group .fg-line {
  padding: 0 0px;
}
</style>
