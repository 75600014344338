import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// VeeValidate
import VeeValidate from 'vee-validate';
import { Validator } from "vee-validate";
Vue.use(VeeValidate);

const dictionary = {
  en: {
    messages:{
      required: (field) => `El campo es requerido.`,
      alpha: (field) => `El campo debe contener solo caracteres alfabéticos.`,
      alpha_spaces: (field) => `El campo debe contener solo caracteres alfabéticos y espacios.`,
      numeric: (field) => `El campo debe contener solo caracteres numéricos.`,
      min: (field, params) => `El campo debe contener al menos ${params[0]} caracteres.`,
      max: (field, params) => `El campo no debe contener más de ${params[0]} caracteres.`,
      between: (field, params) => `El campo debe estar entre los números ${params[0]} y ${params[1]} inclusive.`,
      email: (field) => `El campo debe ser un Correo Electrónico válido.`,
    }
  }
};

// Override and merge the dictionaries
Validator.localize(dictionary);

//Custom Rules
Validator.extend('userNameValidate', {
  getMessage: (field) => 'El campo solo puede contener minúsculas, ".", "_" o "-".',
  validate: (value) => {
    return /(^[a-z0-9\-._]*$)/.test(value);
    //return /^[a-z0-9](\.?[a-z0-9]){5,}/.test(value);
    /* return /'^[a-z]+$/g.test(value); */
  }
})

Validator.extend('alpha_numeric', {
  getMessage: (field) => 'El campo no debe contener caracteres especiales ni espacios.',
  validate: (value) => {
    return /(^[a-zA-Z0-9._-]*$)/.test(value);
  }
})

Validator.extend('nombre_apellido', {
  getMessage: (field) => 'El campo solo puede obtener letras.',
  validate: (value) => {
    return /(^[a-zA-ZäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙÑñ\s]*$)/.test(value);
  }
})

Validator.extend('dni', {
  getMessage: (field) => 'El campo no puede contener caracteres especiales, excepto guiones en posiciones intermedias.',
  validate: (value) => {
    return /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/.test(value);
  }
})

Validator.extend('cuilFormat', {
  getMessage: (field) => 'El CUIL ingresado debe contener 11 números, sin guiones ni letras.',
  validate: (cuil) => cuil.length === 11
})

Validator.extend('cuilNumber', {
  getMessage: (field) => 'El CUIL debe ser válido.',
  validate: (cuil) => {
    cuil = cuil.replace(new RegExp('-', 'g'), '');
    let acum = 0;
    let digits = cuil.split('');
    let lastDigit = digits.pop();

    for(let i = 0; i < digits.length; i++) {
      acum += digits[9 - i] * (2 + (i % 6));
    }

    let verif = 11 - (acum % 11);
    if(verif == 11) {
        verif = 0;
    } else if(verif == 10) {
        verif = 9;
    }
    return lastDigit == verif;
  }
})

Validator.extend('alphanum_underscore', {
  getMessage: (field) => 'El campo no debe contener caracteres especiales,espacios ni mayúsculas',
  validate: (value) => {
    //return /(^[a-z0-9._@-]*$)/.test(value);
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
  }
})

Validator.extend('isNotYuliEmail', {
  getMessage: (field) => `El Correo Electrónico de Recuperación no puede ser '@unc.edu.ar ni @mi.unc.edu.ar'.`,
  validate: (value) => {
    if ( /^(?!.*@unc\.edu\.ar).*$/.test(value) && /^(?!.*@mi\.unc\.edu\.ar).*$/.test(value) ) {
      return true
    } else {
      return false
    }
  }
});

Validator.extend('isYuliPassword', {
  getMessage: (field) => 'Debe contener al menos 10 caracteres de longitud, un número, una minúscula y una mayúscula.',
  validate: (value) => {
    if (/\d{1}/.test(value) && /(.*[a-z].*)/.test(value) && /(.*[A-Z].*)/.test(value)) {
      return true;
    } else {
      return false;
    }
  }
});

Validator.extend('requireNumber', {
  getMessage: (field) => 'Debe contener al menos un número.',
  validate: (value) => {
    return  /\d{1}/.test(value);
  }
});

Validator.extend('requireLowerCase', {
  getMessage: (field) => 'Debe contener al menos una minúscula.',
  validate: (value) => {
      return  /\d{1}/.test(value);
  }
});

Validator.extend('requireUpperCase', {
  getMessage: (field) => 'Debe contener al menos una mayúscula.',
  validate: (value) => {
      return  /\d{1}/.test(value);
  }
});

Validator.extend('uncUserName', {
  getMessage: (field) => 'No te olvides de ingresar @unc.edu.ar o @mi.unc.edu.ar',
  validate: (value) => {
      return  (/.*@unc.edu.ar$|.*@mi.unc.edu.ar$/.test(value))?true:false;
  }
});

//Fin VeeValidate

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

//Plugins
import './../node_modules/jquery/dist/jquery.min.js'
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/bootstrap/dist/js/bootstrap.min.js'

//datePicker
import datePicker from 'vue-bootstrap-datetimepicker';
import './../node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'  
Vue.use(datePicker);

//import './assets/vendors/jquery.easy-pie-chart/dist/jquery.easypiechart.min.js'

//Select
import './assets/vendors/bootstrap-select/dist/js/bootstrap-select.min'
import './assets/vendors/bootstrap-select/dist/css/bootstrap-select.min.css'

import swal from './../node_modules/sweetalert2/dist/sweetalert2.min.css'
import './../node_modules/animate.css/animate.min.css'
//import './../node_modules/jquery.nicescroll/dist/jquery.nicescroll.min.js'

//Fonts
import './assets/fonts/Reforma/Reforma Webfonts/fonts.css'
import './../node_modules/roboto-fontface/css/roboto/roboto-fontface.css'
import './../node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.min.css'

//Custom Style
import '../src/assets/scss/my-listview/my-listview.scss'
import '../src/assets/scss/my-form/my-form.scss'
import '../src/assets/scss/my-modal/my-modal.scss'
import '../src/assets/scss/my-app/my-app.scss'
import '../src/assets/scss/my-view-block/my-view-block.scss'
import '../src/assets/scss/my-menu/my-menu.scss'
import '../src/assets/css/app-core.css'
import '../src/assets/css/app-ecart.css'
import  '../src/assets/css/spinner/spinkit.css'

//JS
import './assets/js/app-core.js'
import UncLoader from '../src/components/utils/UncLoader.vue'
Vue.component('unc-loader',UncLoader)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
