//Axios: Peticiones HTTP via Ajax
import axios from 'axios';

export const swal = require('sweetalert2');

export const HttpYULI = axios.create({
  baseURL: '/yulibackend/'
});

export const HttpYULIV2 = axios.create({
  baseURL: '/yulibackendV2/'
});

export const HttpGEO = axios.create({
  baseURL: '/geoobjectbackend/'
});

export const HttpFRONT = axios.create({
  baseURL: '/'
});

