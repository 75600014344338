<template>
<div>

    <div v-if="isInitializing" class="loading">
        <unc-loader></unc-loader>
    </div>

    <div v-else class="card animated fadeIn">
        
        <div class="card-header ch-alt">
            <h2>{{ titulo }} <small>{{currentUser.names}} {{currentUser.lastNames}}</small> </h2>
        </div>

        <div class="card-body card-padding">

            <p>Ingresá la contraseña con la cual inicias sesión en el sistema Guaraní para sincronizar tus datos y continuar con el registro.</p>

            <p v-if="urlRestorePass!=''">En caso de no recordar tu contraseña, ingresá a <a style="cursor:pointer" @click.prevent="goRestorePassword()">Recuperar Contraseña</a> y gestioná una nueva contraseña desde el sistema Guaraní.</p>
            <p v-else>En caso de no recordar tu contraseña, gestioná una nueva contraseña desde el sistema Guaraní.</p>

            <form @submit.prevent="validateBeforeSubmit">
                <div class="form-group" :class="{'has-error': errors.has('user')}">
                    <div class="fg-line">
                        <label>Usuario Guaraní:</label>
                        <br>{{item.user}}
                    </div>
                    <small v-if="errors.has('user')" class="help-block">{{ errors.first('user') }}</small>
                </div>
            
                <div class="form-group" :class="{'has-error': errors.has('pass')}">
                    <div class="fg-line">
                        <label>Contraseña Guaraní:</label>
                        <input v-model="item.pass" id="pass" name="pass" v-validate="'required'" type="password" class="form-control" placeholder="Ingresá tu contraseña...">
                    </div>
                    <small v-if="errors.has('pass')" class="help-block">{{ errors.first('pass') }}</small>
                </div>
            
                <div class="text-right">
                    <button @click.prevent="goBack()" type="button" class="btn btn-link waves-effect">Volver</button>
                    <!-- <button @click.prevent="goRestorePassword()" type="button" class="btn btn-primary waves-effect m-r-10">Recuperar Contraseña</button> -->
                    <button type="submit" class="btn btn-primary waves-effect">Siguiente</button>
                </div>
            </form>
    
        </div> 
    </div>        

</div>
</template>

<script>
    import { HttpFRONT, swal } from '../../share.js';
    import { mapState, mapGetters } from 'vuex';

    export default {
        data() {
            return {
                titulo: 'CREDENCIALES GUARANÍ',
                isInitializing: false,
                item: {
                    user: '',
                    pass: ''
                },
                urlRestorePass: ''
            }
        },
        computed: {
            ...mapState([
            'currentUser'
            ]),
            ...mapGetters([
                'configApp',
             ]),
        },
        mounted() {
            this.item.user = this.currentUser.user;
            this.urlRestorePass = this.configApp.urlRestorePassGuarani || '';
        },
        methods: {
            validateBeforeSubmit(e) {
            
                this.$validator.validateAll().then(() => {
                    if (!this.errors.any()) {
                        this.login();
                    } else {
                        // console.log('No Validated: ', JSON.stringify(this.errors.items)); 
                    }
                }).catch(() => {
                    swal({title: 'Algo salió mal', text: 'No pudimos verificar tus credenciales.', type: 'warning' });
                });
            
            },
            login() {
                let self = this;

                HttpFRONT.post('guaraniLogin', self.item)
                    .then(response => {

                        if (response.status === 200 || response.status === 201) {
                            let user = self.currentUser;
                            user.role = response.data[0].perfil === "UNC" ? "UNC" : "ALUMNO";
                            user.idpersonaguarani = response.data[0].idpersonaguarani;
                            user.mailrecuperacion = response.data[0].mailrecuperacion;
                            this.$store.commit('setCurrentUser',user);
                            this.$store.commit('setCurrentComponent','register-person');
                        }
                        
                    })
                    .catch(error => {
                        let status = error.response.status;
                        if (status === 401) {
                            swal({title: 'Nombre de usuario o contraseña incorrecta', text: 'Tus credenciales para iniciar sesión en el Sistema Guaraní son incorrectas. En caso de que no recuerdes tu contraseña, seleccioná la opción Recuperar Contraseña.', type: 'warning' });
                        } else if (status === 403) {
                            swal({title: 'Acceso Denegado', text: 'No posee permisos para acceder a este servicio.', type: 'warning' });
                        } else {
                            swal({title: 'Algo salió mal', text: 'Servicio temporalmente no disponible.', type: 'warning' });
                        }
                }); 
                    
            },
            goRestorePassword(){
                window.open(this.urlRestorePass,'_blank');
            },
            goBack(){
                this.$store.commit('setCurrentComponent','register-search-results');
            }
        }
    }
</script>

<style lang="scss">

</style>
