
<template>
  <div>

    <section id="primerSeccion">
      <div class="img-principal text-center align-items-center">
        <!-- <h3 class="main-title titulo-principal">USUARIO UNC</h3> -->
        <div class="logo">
            <img src="../../assets/img/unc-landing.png" alt="">
        </div>
        <button class="btn-crear-usuario" @click="createUser">CREAR USUARIO</button>
      </div>
    </section>
    
    <section id="preguntasFrecuentes">

      <div class="container-fluid">
        <div class="row">
          
          <div class="col-xs-10 col-xs-offset-1 col-xs-offset-2 col-sm-4 col-sm-offset-1 col-md-4 col-md-offset-2 col-lg-3 col-lg-offset-2 texto-informativo">
            <div class="row text-center">
              <i class="zmdi zmdi-account-circle zmdi-hc-5x icono-informativo"></i>
              <p class="info-subtitle m-t-10">¿Qué es Usuario UNC?</p>
            </div>
            <div class="row text-justify">
              <div class="info-text">
                Usuario UNC es la identidad universitaria, es decir, un <b class="resaltado">identificador personal y único</b> para cada miembro de nuestra comunidad.
              </div>
            </div>
          </div>

          <div class="col-xs-0 col-sm-2 "></div>

          <div class="col-xs-10 col-xs-offset-1 col-sm-4 col-sm-offset-0 col-md-4 col-lg-3 texto-informativo">
            <div class="row text-center">
              <i class="zmdi zmdi-lock-open zmdi-hc-5x icono-informativo"></i>
              <p class="info-subtitle m-t-10">¿Para qué Usuario UNC?</p>
            </div>
            <div class="row text-justify">
              <div class="info-text">
                Usuario UNC te permite acceder de forma simple, por medio de la identidad universitaria, a los diferentes sistemas y servicios de la Universidad Nacional de Córdoba.
              </div>
            </div>
          </div>

        </div>
      
        <div class="row">
          <div class="col-xs-10 col-xs-offset-1 col-sm-4 col-sm-offset-1 col-md-4 col-md-offset-1 col-lg-3 col-lg-offset-2 texto-informativo">
            <div class="row text-center">
              <i class="zmdi zmdi-account-add zmdi-hc-5x icono-informativo"></i>
              <p class="info-subtitle m-t-10">¿Cómo creo mi usuario UNC?</p>
            </div>
            <div class="row text-justify">
              <div class="info-text">
                Para crear tu Usuario UNC hacé click en <a href="" @click.prevent="createUser()">CREAR USUARIO</a> y seguí los pasos, el proceso te va ha ir guiando hasta el final. Ante cualquier duda en la creación del usuario único consultá el <a href="https://documentacion.psi.unc.edu.ar/manuales/manual_usuario_usuariosUNC/" target="_blank">Tutorial</a>, preguntá en tu Despacho de Alumnos o al administrador de tu dependencia.
              </div>
            </div>
          </div>

          <div class="col-xs-0 col-sm-2"></div>

          <div class="col-xs-10 col-xs-offset-1 col-sm-4 col-sm-offset-0 col-md-4 col-lg-3 texto-informativo">
            <div class="row text-center">
              <i class="zmdi zmdi-google zmdi-hc-5x icono-informativo"></i>
              <p class="info-subtitle m-t-10">¿Cómo accedo a los servicos de Google?</p>
            </div>
            <div class="row text-justify">
              <div class="info-text">
                G Suite es una herramienta de Google para usuarios corporativos o institucionales, en este caso la Universidad Nacional de Córdoba incluye entre sus servicios a: Gmail, Documentos, Chat corporativo, Videoconferencias, Google Drive y muchos otros. 
Para poder habilitar G Suite deberás ingresar a <a href="" @click.prevent="usersProfile()">MiUNC</a> y desde la sección <b class="resaltado">Mis Aplicaciones</b> hacer click sobre el botón Sincronizar en la opción Google G Suite.   
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>

    <section id="seccionContacto" class="seccion-info">
      <div class="text-center">
        <h3 class="section-title">Contactanos</h3>

          <p class="navBar-subtitle">Ante cualquier duda enviá un correo electrónico al Despacho de Alumnos de tu facultad,<br> o al administrador de tu dependencia.</p>
  
        <!-- <p class="navBar-subtitle">Para mayor información o consultas:</p>
        <div style="margin-top: 40px;">
            <p class="navBar-subtitle" ><i class="zmdi zmdi-email zmdi-hc-2x" style="vertical-align: middle; margin-right: 25px;"> </i>usuarios@informatica.unc.edu.ar</p>
        </div> -->
      </div>
    </section>
      
    <hr style="width: 50%; color: #cecccc; height: 1px; background-color:#cecccc; margin-top:25px;" />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
                'configApp',
             ]),
  },
  methods: {
    createUser() {
        this.$router.push({ path: "registro" });
    },
    usersProfile() {
        location.href = this.configApp.urlProfile;
    },
  }
};
</script>

<style lang="scss" scoped>

    #primerSeccion .img-principal {
    background-image: url("../../assets/img/fondosUNC/30.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: 500px;
    }

    #primerSeccion .titulo-principal {
    color: white;
    margin: 0;
    padding: 240px 0 25px 0px;
    }

    #primerSeccion .logo {
      padding: 65px 0 0px 0px;
    }

    #primerSeccion .logo img{
      width: 350px;
    }

    .btn-crear-usuario {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75) !important;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75) !important; 
    font-family: Reforma2018;
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    font-size: 18px;
    background-color: #9f0391 !important; /*53284F!important*/
    color: white;
    border-color: #9f0391;
    border: none;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);

    padding: 10px 32px;
    margin: 15px 0 0 0;
    box-shadow: none;
    }

    .main-title {
    font-family: Reforma2018;
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    font-size: 48px;
    color: #53284f;
    text-shadow: 1px 3px #00000091;
    }

    .info-subtitle {
    font-family: Reforma2018;
    font-style: normal;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    font-size: 24px;
    color: #004566;
    }

    .info-text {
    font-family: Reforma2018;
    font-style: normal;
    font-weight: 300;
    text-rendering: optimizeLegibility;
    font-size: 18px;
    color: #5f6368;
    }

    .texto-informativo {
    margin-top: 80px;
    }

    .icono-informativo {
    color: rgb(100, 181, 246)
    }

    .resaltado {
    color: #004566;
    }

    // Sección contacto
    #seccionContacto {
    margin-top: 80px;
    background-image: url("../../assets/img/fondosUNC/35.jpg");
    }

/*     #seccionContacto .img-principal {
    background-image: url("../../assets/img/fondosUNC/35.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: 350px;
    } */

    #seccionContacto .titulo-principal {
    color: white;
    margin: 0;
    padding: 240px 0 25px 0px;
    }

    // Seccion Servicios
    #seccionServicios {
    margin-top: 80px;
    background-image: url("../../assets/img/fondosUNC/31.jpg");
    }


    
    .seccion-info {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: 350px;
    }

    .section-title{
    font-family: Reforma2018 !important;
    font-style: normal !important;
    font-weight: 700 !important;
    text-rendering: optimizeLegibility !important;
    font-size: 32px !important;
    text-shadow: 1px 3px #00000091 !important;
    text-align: center !important;
    color: white;
    padding-top: 55px;
    margin-bottom: 40px;
    }

    hr.style1{
        border-top: 1px solid #8c8b8b;
    margin-top:40px;
    width: 850px;
    }

    .vcenter {
        display: inline-block;
        vertical-align: middle;
        float: none;
    }

</style>