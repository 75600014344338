import Vue from 'vue'
import Router from 'vue-router'
import vueSmoothScroll from 'vue2-smooth-scroll'

import landingPage from "./components/landing/landing.vue";
import registerPage from "./components/register/register-layout.vue";
import registerUserPage from "./components/register/register-user-form.vue";
import restorePasswordPage from "./components/register/restore-password.vue";
import restoreChangePasswordPage from "./components/register/restore-change-password.vue";
import confirmEmaildPage from "./components/register/confirm-email.vue";
import confirmRecoveryEmailPage from "./components/register/confirm-recovery-email.vue";



const misRutas = [
  { 
    path: '*', 
    component: landingPage
  },
  { 
      path: '/', 
      component: landingPage
  },
  { 
    path: '/registro',
    component: registerPage
  },
  { 
    path: '/registro-usuario/:token?', 
    component: registerUserPage,
    props: true
  },
  { 
    path: '/recuperar-clave', 
    component: restorePasswordPage
  },
  { 
    path: '/nueva-clave/:token?', 
    component: restoreChangePasswordPage,
    props: true
  },
  { 
    path: '/confirmar-correo/:token?', 
    component: confirmEmaildPage,
    props: true
  },
  { 
    path: '/cambiar-correo-recuperacion/:token?', 
    component: confirmRecoveryEmailPage,
    props: true
  },
];

Vue.use(vueSmoothScroll);
Vue.use(Router)

// const router = new Router({
// //   mode: 'history',
//   base: process.env.BASE_URL,
//   routes: misRutas
// })

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: misRutas
})


export default router;
