
<template>
<div class="container m-t-30">
    <div v-if="showLoading" class="loading">
        <unc-loader></unc-loader>
    </div>

    <div v-else class="row animated fadeIn">
        <div class="col-md-3 col-sm-2 col-xs-12"></div>

        <div class="col-md-6 col-sm-8 col-xs-12">

            <div v-if="currentComponent == 'register-search'">
                <register-search></register-search>
            </div>

            <div v-else-if="currentComponent == 'register-search-results'">
                <register-search-results></register-search-results>
            </div>

            <div v-else-if="currentComponent == 'register-person-rol'">
                <register-person-rol></register-person-rol>
            </div>

            <div v-else-if="currentComponent == 'register-person-form'">
                <register-person-form></register-person-form>
            </div>

            <div v-else-if="currentComponent == 'register-guarani'">
                <register-guarani></register-guarani>
            </div>

            <div v-else-if="currentComponent == 'register-person'">
                <register-person></register-person>
            </div>

        </div>

        <div class="col-md-3 col-sm-2 col-xs-12"></div>

    </div>

</div>

</template>

<script>
import { mapState } from 'vuex'

import registerSearch from "./register-search.vue";
import registerSearchResults from "./register-search-results.vue";
import registerPersonRol from "./register-person-rol.vue";
import registerPersonForm from "./register-person-form.vue";
import registerGuarani from "./register-guarani.vue";
import registerPerson from "./register-person-form.vue";

export default {
    data() {
        return {

        }
    },
    computed: mapState([
        'people',
        'currentPerson',
        'currentComponent',
        'showLoading'
    ]),
    components: {
        registerSearch,
        registerSearchResults,
        registerPersonRol,
        registerPersonForm,
        registerGuarani,
        registerPerson
    },
    mounted() {
        this.$store.commit('setCurrentComponent','register-search');
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>


</style>
