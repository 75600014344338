<template>
  <div class="container m-t-30">
    <div v-if="isLoading" class="loading">
      <unc-loader></unc-loader>
    </div>

    <div v-else class="row">
      <div class="col-md-3 col-sm-2 col-xs-12"></div>
      <div class="col-md-6 col-sm-8 col-xs-12">
        <div class="card animated fadeIn">
          <div class="card-header ch-alt">
            <h2>
              {{ titulo }}
              <small>Ingresá tu Usuario UNC para iniciar el proceso de recuperación de contraseña. Recordá de añadir @unc.edu.ar o @mi.unc.edu.ar</small>
            </h2>
          </div>

          <div class="card-body card-padding">
            <form @submit.prevent="validateBeforeSubmit">
            
              <div class="form-group" :class="{'has-error': errors.has('item.username')}">
                <div class="fg-line">
                  <label>Usuario UNC</label>
                  <input
                    v-model="item.username"
                    id="item.username"
                    name="item.username"
                    v-validate="'required|uncUserName'"
                    type="text"
                    class="form-control"
                    placeholder="Ingresá tu Usuario UNC..."
                  />
                </div>
                <small
                  v-if="errors.has('item.username')"
                   class="help-blocks"
                >{{ errors.first('item.username') }}</small>
              </div>

              <div v-if="enabledCaptcha" class="row m-b-30 g-recaptcha">
                <div class="col-md-12">
                  <vue-recaptcha :sitekey="configCaptcha" :loadRecaptchaScript="true" ></vue-recaptcha>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-right">
                  <button
                    @click.prevent="goBack()"
                    type="button"
                    class="btn btn-link waves-effect"
                  >Volver</button>
                  <button type="submit" class="btn btn-primary waves-effect">Recuperar contraseña</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HttpFRONT,
  swal
} from "../../share.js";
import VueRecaptcha from "vue-recaptcha";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      titulo: "RECUPERAR CONTRASEÑA",
      isLoading: false,
      item: {
        identifier: "",
        code: "",
        countryCode: "",
        countrySelected: "",
        username: ""
      },
      optionsCountries: null,
      allIdentityTypes: null,
      recoveryEmail: "",
    };
  },
  computed: {
    ...mapGetters([
        'configCaptcha',
    ]),
    enabledCaptcha () {
        return !!this.configCaptcha;
    },
  },
  components: {
    VueRecaptcha
  },
  mounted() {
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator
        .validateAll()
        .then(() => {
          if (!this.errors.any()) {
            if (!this.enabledCaptcha) {
              return this.recover();
            }

            let captchaResponse = grecaptcha.getResponse(this.rcapt_id);
            if (captchaResponse.length > 0) {
              grecaptcha.reset();
              this.recover();
            } else {
              swal({
                title: "",
                text: "Por favor, indicá que no eres un robot.",
                type: "info"
              });
            }
          }
        })
        .catch(() => {
          swal({
            title: "Algo salió mal",
            text: "No pudimos recuperar tu contraseña.",
            type: "warning"
          });
        });
    },
    recover() {
      let self = this;
      self.isLoading = true;
      let dataToSend = {
        username: self.item.username.trim()
      };

      HttpFRONT.post("usuarios/recuperar-clave-token-v2", dataToSend)
        .then(response => {
          if (response.status === 200) {
            self.recoveryEmail = response.data;
            let htmlMessage = `Enviamos un correo a <strong>${self.recoveryEmail}</strong>. 
                        Ingresá a tu casilla y seguí las instrucciones para continuar con la recuperación de la contraseña.`;
            self.$router.push("/");
            swal({ title: "", html: htmlMessage, type: "success" });
          } else {
            swal({
              title: "Algo salió mal",
              text: "Servicio temporalmente no disponible.",
              type: "warning"
            });
          }
        })
        .catch(error => {
          let status = error.response.status;
          let title = "";
          let text = "";

          switch (status) {
            case 404:
              title = "Recuperación Incorrecta";
              text =
                "No se encontró un Usuario UNC para recuperar contraseña.";
              break;
            case 412:
              //tiene más de un usuario activo.. tiene que elegir cual.
              // vm.systems = err.data.systems;
              // return;

              // title = '';
              // text = '';
              break;
            case 422:
              title = "Recuperación Incorrecta";
              text =
                "No hay un correo electrónico de recuperación asociado al Usuario UNC ingresado.";
              break;
            default:
              title = "Algo salió mal";
              text = "Servicio temporalmente no disponible.";
              break;
          }

          if (title != "" && text != "") {
            swal({ title: title, html: text, type: "warning" });
          }
        })
        .finally(() => {
          self.isLoading = false;
        });
    },

    goBack() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss">

.g-recaptcha {transform: scale(0.85); transform-origin: left top;}

</style>
