<template>
  <div class="container m-t-30">
    <div v-if="isInitializing">
      <br>
      <div style="text-align:center">Estamos obteniendo información...</div><br>
      <div style = "margin:0px 48%!important" class="loading" >
        <unc-loader></unc-loader>
      </div>
    </div>

    <div v-else-if="showNotFound" class="row animated fadeIn">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="jumbotron text-center">
          <h2>Usuario no encontrado.</h2>
          <p>No se ha encontrado ningún usuario con el identificador recibido o el mismo expiró.</p>
          <button
            @click.prevent="goBack()"
            type="submit"
            class="btn btn-primary btn-lg m-t-10"
          >Volver</button>
        </div>
      </div>
    </div>

    <div v-else class="card animated fadeIn">
      <div class="card-header ch-alt">
        <h2>
          {{ titulo }}
          <small>Completá el formulario para crear tu nombre de Usuario UNC.</small>
        </h2>
      </div>

      <div class="card-body card-padding">
        <form @submit.prevent="validateBeforeSubmit">
          <label>NOMBRE DE USUARIO</label>
          <p>
            A continuación se listan algunas de las opciones disponibles para que selecciones tu nombre de usuario. En caso de desear especificar otro nombre de usuario, presioná sobre la opción 'Otro' y escribí tu nombre de usuario válido.
            <a
              href
              @click.prevent="showRulesUserName=!showRulesUserName"
            >{{ showRulesUserName ? 'Ocultar' : 'Mostrar' }} reglas para nombres de usuarios.</a>
          </p>

          <div v-if="showRulesUserName" class="alert alert-info" role="alert">
            <p>
              <strong>Reglas para Nombres de Usuarios</strong>
            </p>
            <p>Los nombres de usuarios deben seguir las siguientes reglas, respetando su orden:</p>
            <br />
            <ul>
              <li>Contener una o varias letras consecutivas desde el inicio de alguno de tus nombres.</li>
              <li>Alguno de tus apellidos completo.</li>
              <li>Opcionalmente puede estar seguido de uno o más números.</li>
            </ul>

            <p>
              <br />Las diferentes partes pueden, opcionalmente, estar separadas por:
            </p>
            <br />
            <ul>
              <li>Guión bajo (_), Guión medio (-), Punto (.).</li>
            </ul>

            <p>
              <br />El nombre de usuario no debe superar los 64 caracteres.
            </p>
          </div>
          <div v-if="isLoading" class="loading" >
            <unc-loader></unc-loader>
          </div>
          <div v-else>
            <div class="radio m-b-20" v-for="user of optionsUserNames" :key="user">
              <label>
                <input v-model="selectedUserName" type="radio" name="userName" :value="user" />
                <i class="input-helper"></i>
                {{user}}<span style="color:grey">@{{ userDomain }}</span>
              </label>
            </div>
            <div class="radio m-b-20">
              <label>
                <input v-model="selectedUserName" type="radio" name="userName" value="otro" />
                <i class="input-helper"></i>
                Otro
              </label>
            </div>
            <div
              v-if="isOtroSelected"
              class="form-group"
              :class="{'has-error': errors.has('userNameOtro')}"
            >
              <div class="row">
                  <div class="col-xs-6">
                    <div class="fg-line">
                      <input
                        maxidth=10
                        v-model="userNameOtro"
                        id="userNameOtro"
                        name="userNameOtro"
                        v-validate="'required|userNameValidate'"
                        type="text"
                        class="form-control"
                        placeholder="Ingresá tu nombre de usuario..."
                        onkeyup="this.value = this.value.toLowerCase();"
                      />
                    </div>
                  </div>
                <div class="col p-t-10"><span style="color:grey">@{{ userDomain }}</span></div>
              </div>
            <small
              v-if="errors.has('userNameOtro')"
              class="help-block"
            >{{ errors.first('userNameOtro') }}</small>
            </div>
          </div>

          <label>CONTRASEÑA</label>

          <p>
            Ingresá una contraeña válida.
            <a
              href
              @click.prevent="showRulesPassword=!showRulesPassword"
            >{{ showRulesPassword ? 'Ocultar' : 'Mostrar' }} reglas para contraseñas.</a>
          </p>

          <div v-if="showRulesPassword" class="alert alert-info" role="alert">
            <p>
              <strong>Reglas para Contraseñas</strong>
            </p>
            <p>La contraseña debe respetar las siguientes reglas:</p>
            <ul>
              <li>Tener como mínimo 10 caracteres de longitud.</li>
              <li>Contener letras y números.</li>
              <li>Contener al menos un número.</li>
              <li>Contener al menos una letra mayúscula.</li>
              <li>Contener al menos una letra minúscula.</li>
            </ul>
          </div>

          <div class="form-group" :class="{'has-error': errors.has('password')}">
            <div class="input-group">
              <div class="fg-line text-left">
                <label for="password">Ingrese una contraseña</label>
                <input
                  :type="passwordFieldType"
                  v-model="item.password"
                  id="password"
                  name="password"
                  v-validate="'required|isYuliPassword|min:10'"
                  class="form-control"
                  placeholder="Ingresá una contraseña válida..."
                  value
                />
              </div>
              <span
                v-if="passwordFieldType=='password'"
                @click.prevent="showPassword(true)"
                class="input-group-addon last"
              >
                <i class="zmdi zmdi-eye-off zmdi-hc-2x"></i>
              </span>
              <span v-else @click.prevent="showPassword(false)" class="input-group-addon last">
                <i class="zmdi zmdi-eye zmdi-hc-2x"></i>
              </span>
            </div>
            <small
              v-if="errors.has('password')"
              class="help-block text-left"
            >{{ errors.first('password') }}</small>
          </div>

          <div
            class="form-group"
            :class="{'has-error': errors.has('passwordConfirmation') || showValidationErrorPasswordConfirmation}"
          >
            <div class="input-group">
              <div class="fg-line text-left">
                <label for="passwordConfirmation">Repetir contraseña:</label>
                <input
                  :type="passwordConfirmationFieldType"
                  @keyup="showValidationErrorPasswordConfirmation=false"
                  v-model="item.passwordConfirmation"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  v-validate="'required'"
                  class="form-control"
                  placeholder="Ingresá nuevamente tu contraseña..."
                  value
                />
              </div>
              <span
                v-if="passwordConfirmationFieldType=='password'"
                @click.prevent="showPassword(true, true)"
                class="input-group-addon last"
              >
                <i class="zmdi zmdi-eye-off zmdi-hc-2x"></i>
              </span>
              <span v-else @click.prevent="showPassword(false,true)" class="input-group-addon last">
                <i class="zmdi zmdi-eye zmdi-hc-2x"></i>
              </span>
            </div>
            <small
              v-if="errors.has('passwordConfirmation')"
              class="help-block text-left"
            >{{ errors.first('passwordConfirmation') }}</small>
            <small
              v-if="showValidationErrorPasswordConfirmation"
              class="help-block"
            >Las contraseñas no coinciden</small>
          </div>

          <div class="text-right">
            <button
              @click.prevent="goBack()"
              type="button"
              class="btn btn-link m-t-10 waves-effect"
            >Volver</button>
            <button type="submit" class="btn btn-primary m-t-10 waves-effect">Siguiente</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HttpYULI,
  HttpYULIV2,
  swal,
  HttpFRONT,
} from "../../share.js";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      titulo: "REGISTRO DE USUARIOS UNC",
      isInitializing: true,
      isLoading: true,
      item: {
        userName: "",
        password: "",
        passwordConfirmation: ""
      },
      user: "",
      selectedUserName: "",
      userNameOtro: "",
      optionsUserNames: null,
      showNotFound: false,
      showRulesUserName: false,
      showRulesPassword: false,
      showValidationErrorPasswordConfirmation: false,
      passwordFieldType: "password",
      passwordConfirmationFieldType: "password",
      userDomain: ''
    };
  },
  props: ["token"],
  computed: {
    ...mapGetters([
                'configApp',
    ]),
    isOtroSelected: function() {
      if (this.selectedUserName == "otro") {
        return true;
      } else {
        this.item.userNameOtro = "";
        return false;
      }
    }
  },
  mounted() {
    if (this.token) {
      this.getData();
    } else {
      this.showNotFound = true;
      this.isInitializing = false;
    }
  },
  methods: {
    getData() {
      let self = this;

      // HttpYULIV2.get(`tokens/${this.token}/userOnly`)
      HttpYULIV2.get(`people?token=${this.token}`)
        .then(responseUser => {
          if (responseUser) {
            self.user = responseUser.data[0];
            if (self.user._id && (self.user.state.code === "V" || self.user.state.code ==="PE")) {
              // El token existe, el _id existe, y el estado es Valid
              // let personId = self.user.person;
              let personId = self.user._id;
              self.userDomain = self.user.profile.domain
              self.isLoading = true;
              self.getUserNames(personId).then(response => {
                if (response.data) {
                  self.optionsUserNames = response.data;
                  self.selectedUserName = self.optionsUserNames.length > 0 ? self.optionsUserNames[0] : "otro";
                } else {
                  self.showNotFound = true;
                }
                self.isLoading = false;
              });
            } else {
              self.showNotFound = true;
            }
          } else {
            self.showNotFound = true;
          }
        })
        .catch(error => {
          self.showNotFound = true;
        })
        .finally(() => {
          self.isInitializing = false;
        });
    },
    getUserNames(personId) {
      return HttpYULI.get(`person/${personId}/usernames`);
    },
    validateBeforeSubmit() {
      this.$validator
        .validateAll()
        .then(() => {
          if (!this.errors.any()) {
            if (
              this.validatePasswordConfirmation(
                this.item.password,
                this.item.passwordConfirmation
              )
            ) {
              this.save();
            } else {
              this.showValidationErrorPasswordConfirmation = true;
            }
          }
        })
        .catch(() => {
          swal({
            title: "Algo salió mal",
            text: "Sus datos no fueron registrados.",
            type: "warning"
          });
        });
    },
    validatePasswordConfirmation(password, passwordConfirmation) {
      return password == passwordConfirmation ? true : false;
    },
    save() {
      let self = this;

      self.item.userName = self.isOtroSelected
        ? self.userNameOtro.toLowerCase()
        : self.selectedUserName;

      let userToSend = {
        person: self.user.person,
        userName: self.item.userName,
        password: self.item.password,
        token: self.token
      };

      HttpFRONT.post(`usuarios`, userToSend)
        .then(response => {
          if (response.status === 201 || response.status === 200) {
            let msg = "";
            console.log("Response.data.estado:",response.data.estado)
            let urlProfile = this.configApp.urlProfile || "";
            if (urlProfile != "") {
                msg += `<p>Ahora, con tu usuario: <strong> ${response.data.userName} </strong> podés sincronizar tus aplicaciones ingresando a <a href="${urlProfile}" target="_blank">MiUNC</a>. No olvides ingresar tu nombre de usuario completo.</p>`;
             }
           

            swal({
              title: `Usuario UNC creado satisfactoriamente`,
              html: msg,
              type: "success",
              confirmButtonText: "Ir a MiUNC"
            }).then(function(){
              window.location = urlProfile;  
            });
            this.$router.push("/");
          }
        })
        .catch(error => {
          let status = error.response.status;
          if (status === 400) {
            swal({
              title: "Tu usuario no fue creado",
              text: "Faltan campos requeridos.",
              type: "warning"
            });
          } else if (status === 401) {
            swal({
              title: "Acceso Denegado",
              text: "No posee permisos para acceder a este servicio.",
              type: "warning"
            });
          } else if (status === 409) {
            let message = error.response.data;
            swal({
              title: "Tu usuario no fue creado",
              text: message,
              type: "warning"
            });
          } else {
            swal({
              title: "Algo salió mal",
              text: "Servicio temporalmente no disponible.",
              type: "warning"
            });
          }
        });
    },
    goBack() {
      this.$router.push("/");
    },
    showPassword(show = true, confirmation = false) {
      let fieldType = show ? "text" : "password";

      if (confirmation) {
        this.passwordConfirmationFieldType = fieldType;
      } else {
        this.passwordFieldType = fieldType;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input-group .fg-line {
  padding: 0 0px;
}
</style>
