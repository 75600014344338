import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { HttpFRONT } from '../share';

Vue.use(Vuex);

const state = {
  isInitStatus: false,
  showLoading: false,
  searchIdentifier: {
    _id: '',
    code: '',
    name: '',
    countryCode: '',
    number: ''
  },
  searchResult: '',
  currentUser: null,
  currentComponent: 'register-search',
  selectedPersonRol: '',
  identityTypes: [],
  optionsCountries: [],
  config:{
    app: {},
    google: {
      captcha: null
    },
    invalidDomains: '',
  },
};

export default new Vuex.Store({
  state: state,
  mutations: {
    setIdentityTypes(state, value){
      state.identityTypes = value;
    },
    setOptionsCountries(state, value){
      state.optionsCountries = value;
    },
    setSelectedPersonRol(state, value){
      state.selectedPersonRol = value;
    },
    setShowLoading(state, value){
      state.showLoading = value;
    },
    setSearchIdentifier(state, searchIdentifier){
      state.searchIdentifier = searchIdentifier;
    },
    setSearchResult(state, result){
      state.searchResult = result;
    },
    setCurrentUser(state, user){
      state.currentUser = user;
    },
    setCurrentComponent(state, component){
      state.currentComponent = component;
    },
    setConfig(state, config){
      state.config = config;
    },
    InitStatus(state){
      state.isInitStatus = true;
    },
    logout(state){
      state.isInitStatus = false;
    }
  },
  actions: {
    async InitStatus({commit,dispatch}){
      await dispatch('getConfig');
      commit('InitStatus');
    },
    cleanCurrentUser({commit}){
      commit('setSearchResult', '');
      commit('setCurrentUser', null);
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('logout');
        resolve();
      })
    },
    async getConfig({commit}){
      const config = await HttpFRONT.get('/config')
      commit('setConfig', config.data)
    }
  },
  getters : {
    hasUserYuli: state => {
      //Cuando alguien esta en pending, debe retornar false, porque es como si volviera a empezar
      return (state.searchResult.users.length>0 && (state.searchResult.users[0].status && (state.searchResult.users[0].status != 'P' && state.searchResult.users[0].status != 'PE' && state.searchResult.users[0].status != 'PD'))) ? true : false;
    },
    hasUserYuliActive: state => {
      return (state.searchResult.users.length>0 && state.searchResult.users[0].status === 'A') ? true : false;
    },
    hasUserYuliValid: state => {
      return (state.searchResult.users.length>0 && state.searchResult.users[0].status === 'V') ? true : false;
    },
    hasUserYuliSuspended: state => {
      return (state.searchResult.users.length>0 && state.searchResult.users[0].status === 'S') ? true : false;
    },
    userYuli: state => {
      return state.searchResult.users[0].user;
    },
    yuliPersonId: state => {
      return state.searchResult.yuliPersonId;
    },
    users: state => {
      return state.searchResult.users;
    },
    configApp: state => {
      return state.config && state.config.app;
    },
    configCaptcha: state => {
      return state.config?.google?.captcha;
    },
    invalidDomains: state => {
      return state.config && state.config.invalidDomains;
    },
  },
  modules: {

  }
})