<template>

  <div>
    <div v-if="isLoading" class="loading">
      <unc-loader></unc-loader>
    </div>

    <div v-else-if="hasUserYuli" class="card animated fadeIn">
      <div class="card-header ch-alt">
        <h2>
          UNA COINCIDENCIA ENCONTRADA
          <small>Encontramos una coincidencia para el número de documento {{ searchIdentifier.code.split('_')[1]}} {{ searchIdentifier.number }}.</small>
        </h2>
      </div>

      <div class="card-body card-padding">
        <div v-if="hasUserYuliActive">
          <p>
            Ya existe registrado el usuario
            <strong>{{ userYuli }}</strong>
            para el número de documento {{ searchIdentifier.code.split('_')[1]}} {{ searchIdentifier.number }}.
            <span
              v-if="urlProfile!=''"
            >Presioná el botón 'Ir a MiUNC' para iniciar sesión y habilitar tus aplicaciones.</span>
          </p>
          <p>{{ contactUsMessage }}</p>
          <div class="row m-t-30">
            <div class="col-md-12 text-right">
              <button @click="goBack()" type="button" class="btn btn-link waves-effect">Volver</button>
              <button @click="goProfile()" class="btn btn-primary waves-effect">Ir a MiUNC</button>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="hasUserYuliValid">
            <p>Ya iniciaste el registro de tu Usuario UNC. Si aún no recibiste el correo para continuar el proceso, a continuación podrás reenviártelo.</p>
            <p>{{ contactUsMessage }}</p>
            <div class="row m-t-30">
              <div class="col-md-12 text-right">
                <button @click="goBack()" type="button" class="btn btn-link waves-effect">Volver</button>
                <button @click="reSendEmail()" class="btn btn-primary waves-effect">Reenviar correo</button>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="hasUserYuliSuspended">
              <p>Su usuario se encuentra temporalmente suspendido.</p>
              <p>{{ contactUsMessage }}</p>
              <div class="row m-t-30">
                <div class="col-md-12 text-right">
                  <button @click="goBack()" type="button" class="btn btn-link waves-effect">Volver</button>
                </div>
              </div>
            </div>
            <div v-else>
              <p>
                Ya existe registrado el usuario
                <strong>{{ userYuli }}</strong>
                para el número de documento {{ searchIdentifier.number }}, pero el mismo no se encuentra activo. Comunícate con el administrador de tu dependencia para solicitar la activación de tu usuario.
              </p>
              <p>{{ contactUsMessage }}</p>
              <div class="row m-t-30">
                <div class="col-md-12 text-right">
                  <button @click="goBack()" type="button" class="btn btn-link waves-effect">Volver</button>
                  <button
                    v-if="urlProfile!=''"
                    @click="goProfile()"
                    class="btn btn-primary waves-effect"
                  >Ir a MiUNC</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="users.length == 0" class="card animated fadeIn">
        <div class="card-header ch-alt">
          <h2>CREAR USUARIO UNC</h2>
        </div>

        <div class="card-body card-padding">
          <p>
            No encontramos coincidencias en nuestros sistemas para el número de documento
            <strong>{{ searchIdentifier.code.split('_')[1]}} {{ searchIdentifier.number }}</strong>.
          </p>
          <p>Presioná el botón 'Siguiente' si deseas continuar con el registro.</p>

          <div class="row m-t-30">
            <div class="col-md-12 text-right">
              <button @click="goBack()" type="button" class="btn btn-link waves-effect">Volver</button>
              <button @click="goRegisterPerson()" class="btn btn-primary waves-effect">Siguiente</button>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="users.length == 1" class="card animated fadeIn">
        <div class="card-header ch-alt">
          <h2>
            UNA COINCIDENCIA ENCONTRADA
            <small>Encontramos una coincidencia para el número de documento {{ searchIdentifier.number }}.</small>
          </h2>
        </div>

        <div class="card-body card-padding">
          <p>Seleccioná la opción si la consideras correcta y presioná el botón 'Siguiente' para continuar con el registro.</p>

          <form>
            <div class="radio m-b-20" v-for="item of users" v-validate="'required'" :key="item">
              <label>
                <input v-model="selectedPerson" type="radio" name="person" :value="item" />
                <i class="input-helper"></i>
                {{ item.names }} {{ item.lastNames }}
                <div
                  v-if="item.identities.length > 0"
                >{{ item.identities[0].identityType.code }} {{ item.identities[0].identityNumber }}</div>
              </label>
            </div>

            <p>
              En caso de no figurar en el listado envíanos un correo electrónico a la dirección
              <strong>{{emailHelpdesk}}</strong> comentándonos de tu situación.
            </p>

            <div class="row m-t-30">
              <div class="col-md-12 text-right">
                <button
                  @click.prevent="goBack()"
                  type="button"
                  class="btn btn-link waves-effect"
                >Volver</button>
                <button @click.prevent="goNext()" class="btn btn-primary waves-effect">Siguiente</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div v-else-if="users.length > 1" class="card animated fadeIn">
        <div class="card-header ch-alt">
          <h2>
            COINCIDENCIAS ENCONTRADAS
            <small>Encontramos {{users.length}} coincidencias en nuestros sistemas para el número de documento {{ searchIdentifier.number }}.</small>
          </h2>
        </div>

        <div class="card-body card-padding">
          <p>Seleccioná la opción que consideres correcta y presioná el botón 'Siguiente' para continuar con el asistente de registro.</p>

          <form>
            <div class="radio m-b-20" v-for="item of users" v-validate="'required'" :key="item">
              <label>
                <input v-model="selectedPerson" type="radio" name="person" :value="item" />
                <i class="input-helper"></i>
                {{ item.names }} {{ item.lastNames }}
                <div
                  v-if="item.identities.length > 0"
                >{{ item.identities[0].identityType.code }} {{ item.identities[0].identityNumber }}</div>
              </label>
            </div>
            <p class="m-b-30">
              En caso de no figurar en el listado envíanos un correo electrónico a la dirección
              <strong>{{emailHelpdesk}}</strong> comentándonos de tu situación.
            </p>
            <div class="row m-t-30">
              <div class="col-md-12 text-right">
                <button
                  @click.prevent="goBack()"
                  type="button"
                  class="btn btn-link waves-effect"
                >Volver</button>
                <button @click.prevent="goNext()" class="btn btn-primary waves-effect">Siguiente</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swal, HttpFRONT } from "../../share.js";
import { mapState } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      titulo: "COINCIDENCIAS ENCONTRADAS",
      selectedPerson: "",
      emailHelpdesk: "",
      urlProfile: "",
      contactUsMessage: "",
      isLoading: false
    };
  },
  computed: {
    ...mapState(["searchIdentifier"]),
    ...mapGetters([
      "hasUserYuli",
      "hasUserYuliActive",
      "hasUserYuliValid",
      "hasUserYuliSuspended",
      "userYuli",
      "users",
      "configApp"
    ])
  },
  mounted() {
    this.emailHelpdesk = this.configApp.emailHelpdesk;
    this.urlProfile = this.configApp.urlProfile || "";
    this.contactUsMessage = this.configApp.contactUsMessage || "";
  },
  methods: {
    goNext() {
      if (this.selectedPerson == "") {
        swal({
          title: "Opción Incorrecta",
          text: "Seleccioná una opción válida.",
          type: "warning"
        });
      } else {
        this.$store.commit("setCurrentUser", this.selectedPerson);

        switch (this.selectedPerson.origin) {
          case "GUARANI":
            this.$store.commit("setCurrentComponent", "register-person-rol");
            break;
          case "YULI":
            this.$store.commit("setCurrentComponent", "register-person-rol");
            break;
        }
      }
    },
    goRegisterPerson() {
      this.$store.dispatch("cleanCurrentUser");
      this.$store.commit("setCurrentComponent", "register-person-rol");
    },
    goProfile() {
      window.open(this.urlProfile, "_blank");
    },
    reSendEmail() {
      this.isLoading = true;
      HttpFRONT.post('/personas/' + this.users[0].idPersonaYuli + '/reSendEmail')
      .then(res => {
        if (res.status === 201 || res.status === 200) {
                        swal({title:  'Correo reenviado a '+this.users[0].emailRecuperation, text: `Revisá tu correo para continuar con el proceso de creación de Usuario UNC.`, type: 'success' });
                        this.$router.push('/');
                    }
      }).catch(error => {
        let status = error.response.status;
        switch (status) {
          case 401:
            swal({title: 'Acceso Denegado', text: 'No posee permisos para acceder a este servicio.', type: 'warning' });
            break;
          default:
            swal({title: 'Algo salió mal', text: 'Servicio temporalmente no disponible.', type: 'warning' });
            break;
        }
      }).finally(() => {
          this.isLoading = false;
        }); 
    },
    goBack() {
      this.$store.dispatch("cleanCurrentUser");
      this.$store.commit("setCurrentComponent", "register-search");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
