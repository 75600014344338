
<template>
<div>
    <div v-if="isLoading" class="loading">
        <unc-loader></unc-loader>
    </div>

    <div v-else class="card animated fadeIn">
        <div class="card-header ch-alt">
            <h2>{{titulo}} <small>Vamos a guiarte en la creación de tu Usuario UNC. Por favor, completá el formulario y presioná siguiente para continuar.</small></h2>
        </div>

        <div class="card-body card-padding">

            <form @submit.prevent="validateBeforeSubmit">
                <div class="form-group" :class="{'has-error': errors.has('countryCode')}">
                    <div class="fg-line">
                        <label>País:</label>
                        <div class="select">
                            <select v-model="item.countrySelected" id="countryCode" name="countrySelected" v-validate="'required'" class="form-control">
                                <option value="">[Seleccione una opción]</option>
                                <option v-for="country of optionsCountries" :key="country.code_iso_alfa3" :value="country">{{ country.name_iso }}</option>
                            </select>
                        </div>
                    </div>
                    <small v-if="errors.has('countryCode')" class="help-block">{{ errors.first('countryCode') }}</small>
                </div>

                <div class="form-group" :class="{'has-error': errors.has('identityTypeId')}">
                    <div class="fg-line">
                        <label>Tipo de Documento:</label>
                        <div class="select">
                            <select v-model="item.code" id="identityTypeId" name="identityTypeId" v-validate="'required'" class="form-control">
                                <option value="">[Seleccioná una opción]</option>
                                <!-- <option v-for="opt of optionsIdentityTypes" :key="opt.id" :value="opt.id">{{ opt.code.replace('ARG_', '') }}</option> -->
                                <option v-for="opt of optionsIdentityTypes" :key="opt.id" :value="opt.id">{{ opt.short_name + ' - ' + opt.name }}</option>
                            </select>
                        </div>
                    </div>
                    <small v-if="errors.has('identityTypeId')" class="help-block">{{ errors.first('identityTypeId') }}</small>
                </div>

                <div class="form-group" :class="{'has-error': errors.has('item.identifier')}">
                    <div class="fg-line">
                        <label>Número de Documento</label>
                        <input v-model="item.identifier" id="item.identifier" name="item.identifier" v-validate="selectedIdentityValidation" type="text" class="form-control" placeholder="Ingresá tu número de documento...">
                    </div>
                    <small v-if="errors.has('item.identifier')" class="help-block">{{ errors.first('item.identifier') }}</small>
                </div>

                <div v-if="enabledCaptcha" class="row m-b-30 g-recaptcha">
                    <div class="col-md-12">
                        <vue-recaptcha :sitekey="configCaptcha" :loadRecaptchaScript="true"></vue-recaptcha>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 text-right">
                        <button @click.prevent="goBack()" type="button" class="btn btn-link waves-effect">Volver</button>
                        <button type="submit" class="btn btn-primary waves-effect">Siguiente</button>
                    </div>
                </div>
            </form>
        </div> 
    </div>  

</div>
</template>

<script>
import { HttpFRONT, HttpGEO, swal } from '../../share.js';
import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            titulo: 'EMPECEMOS',
            isLoading: true,
            item: {
                identifier: '',
                code: '',
                countryCode: '',
                countrySelected: '',
            },
            optionsCountries: null,
            allIdentityTypes: null,
        }
    },
    computed: {
        ...mapGetters([
            'configCaptcha',
        ]),
        enabledCaptcha () {
            return !!this.configCaptcha;
        },
        optionsIdentityTypes: function() {
            //Filtro Identity Typhele de base Yuli en base a countryCode de GeoObject
            let countryCode = this.item.countrySelected.code_iso_alfa3;
            let data = this.allIdentityTypes;
            this.item.code = "";

            if(countryCode != "") {
                data = this.allIdentityTypes.filter(function (item) {
                    return item.country_code_iso_alfa3 === countryCode || !item.country_code_iso_alfa3;
                });

                if(data.length==0){
                    //PASS como predeterminado
                    data = this.allIdentityTypes.filter(function (item) {
                        return item.code === "PASS";
                    });
                }
                            
            } else {
                data = [];
            }

            return data;
        },
        selectedIdentityValidation: function() {
            const identityTypeCode = this.allIdentityTypes.find(el => el.id === this.item.code)?.code;
            if(identityTypeCode === "ARG_CUIL"){
                return 'required|min:11|max:13|cuilFormat|cuilNumber';
            }
            if(identityTypeCode === "ARG_DNI"){
                return 'required|between:1000000,99999999|min:7|max:8|dni';
            }
            return 'required';
        }
    },
    components: {
        VueRecaptcha
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            let self = this;

            axios.all([self.getCountries(), self.getIdentityTypes()])
            .then(axios.spread(function (responseCountries,responseIdentityTypes) {
                self.allIdentityTypes = responseIdentityTypes.data;
                self.optionsCountries = responseCountries.data["org.geoobject.model.Country"];
                
                self.$store.commit('setIdentityTypes',self.allIdentityTypes);
                self.$store.commit('setOptionsCountries',self.optionsCountries);
            })).then(() => {
                self.item.countrySelected = self.optionsCountries.find(country => country.name_iso === 'Argentina');
            }).finally(() => {
                self.isLoading = false;
            });
        },
        getCountries(){
            return HttpGEO.get('countries');
        },
        getIdentityTypes(){
            return HttpGEO.get(`identity_types`);
        },
        getPeopleByIdentifier() {
            let self = this;
            
            self.$store.commit('setShowLoading',true);
            self.$store.commit('setSearchResult',[]);
            
            //Completo el searchIdentifier y lo seteo en store 
            let searchIdentifier = {
                _id: '',
                code: '',
                name: '',
                countryCode: '',
                number: ''
            }

            //Obtengo datos de Identity Type seleccionado
            let dataIdentityType = self.optionsIdentityTypes.find(option => {
                return option.id === self.item.code;
            });

            searchIdentifier.number = self.item.identifier;
            searchIdentifier._id = dataIdentityType._id;
            searchIdentifier.code = dataIdentityType.code;
            searchIdentifier.name = dataIdentityType.name;
            searchIdentifier.countryCode = this.item.countrySelected.code_iso_alfa3;
            searchIdentifier.countryName = this.item.countrySelected.name_iso;
            self.$store.commit('setSearchIdentifier', searchIdentifier);

            HttpFRONT.get(`usersdata/${searchIdentifier.number}?identityTypeId=${searchIdentifier.code}`)
            .then(response => {
                if (response.status === 200) {
                    self.$store.commit('setSearchResult',response.data);
                    self.$store.commit('setCurrentComponent','register-search-results');
                }
            }).catch(error => {
                let status = error.response.status;
                if (status === 403) {
                    swal({title: 'Acceso Denegado', text: 'No posee permisos para acceder a este servicio.', type: 'warning' });
                } else {
                    swal({title: 'Algo salió mal', text: 'Servicio temporalmente no disponible.', type: 'warning' });
                }
            }).finally(() => {
                self.$store.commit('setShowLoading',false);
            });  

        },
        validateBeforeSubmit() {
            this.$validator.validateAll().then(() => {
                if (!this.errors.any()) {
                    if(!this.enabledCaptcha) {
                        this.getPeopleByIdentifier();
                    } 
                    else {
                        let captchaResponse = grecaptcha.getResponse(this.rcapt_id);
                        if (captchaResponse.length > 0) {
                            grecaptcha.reset();
                            this.getPeopleByIdentifier();
                        } else {
                            swal({title: '', text: 'Por favor, indicá que no eres un robot.', type: 'info' });
                        }
                    }
                } 
            }).catch((err) => {
                swal({title: 'Algo salió mal', text: 'No pudimos recuperar los datos.', type: 'warning' });
            });
        
        },
        goBack(){
            this.$store.dispatch('cleanCurrentUser');
            this.$router.push('/');
        }
    }
}
</script>

<style lang="scss">

.g-recaptcha {transform: scale(0.85); transform-origin: left top;}

</style>
