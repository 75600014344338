<template>
<div id="app">
    <header id="header">
        <ul class="header-inner">
           <template v-if="this.$route.path==='/'">
            <li  class="btn-collapse" id="menu-trigger" data-trigger="#sidebar">
                        <div class="line-wrap">
                            <div class="line top"></div>
                            <div class="line center"></div>
                            <div class="line bottom"></div>
                        </div>
            </li>
          </template>

          <!-- <li class="logo">
            <a class="navBar-title header-title" @click="principal()">Usuario UNC</a>
          </li> -->
          
            <li class="logo">
                <a href="index.html"><img src="./assets/img/unc-header.png" @click="principal()"></a>
            </li>
    
          <template v-if="this.$route.path==='/'">
            <button class="hidden-items navbar-right btn-miunc-header" @click="usersProfile()">MiUNC</button>
            <button class="hidden-items navbar-right btn-miunc-header" @click="uncEmail()">Correo UNC</button>
            
            <li class="hidden-items navbar-right" style="margin: 10px;">
                <a
                class="navBar-subtitle-2"
                href="https://documentacion.psi.unc.edu.ar/manuales/manual_usuario_usuariosUNC/"
                target="_blank"
                >TUTORIAL
                </a>
            </li>
            <li class="hidden-items navbar-right" style="margin: 10px;"          
                v-for="item of menuItems" :key="item.title">
                <a
                class="navBar-subtitle-2"
                :href="item.href"
                v-smooth-scroll="{ duration: 1200 }"
                >{{ item.title }}
                </a>
            </li>
          </template>
        </ul>
    </header>

    <section id="main">     
        <aside id="sidebar">
            <div class="sidebar-inner c-overflow">
                
                <div class="profile-menu">
                    <a href="">
                        <div class="profile-pic"></div>
                        <div class="profile-info">{{ currentUser }}</div>
                    </a>                    
                </div>

                <ul class="main-menu" id="main-menu"> 
                    <template v-for="item in dataMenu">
                        <li v-if="item.route=='submenu'" class="sub-menu" :key="item">
                            <a href="" :class="item.class"><i :class="item.icon"></i>{{ item.title }}</a>
                            <ul>
                                <li v-for="subitem in item.submenu" :key="subitem.title">
                                    <a href="" @click.prevent="goRoute(subitem)"><i :class="subitem.icon"></i>{{ subitem.title }}</a>
                                </li>
                            </ul>
                        </li>
                        <li v-else >
                            <a :href="item.route" v-smooth-scroll="{ duration: 1200 }" :class="item.class"><i :class="item.icon"></i>{{ item.title }}</a>
                        </li>

                    </template> 
                        <li>
                            <a href="" @click.prevent="uncEmail()"><i class="zmdi zmdi-email"></i>Correo UNC</a>
                        </li>
                        <li>
                            <a href="" @click.prevent="usersProfile()"><i class="zmdi zmdi-account"></i>MiUNC</a>
                        </li>

                </ul>
            </div>
        </aside><!--/.sidebar-->

        <section id="content">
            <div id="main-body">
                <router-view></router-view>
            </div>
        </section><!--/.content-->
        
    </section><!--/.main-->

    <footer id="footer">
      <img src="@/assets/img/fondosUNC/unc-logo-footer.png" height="60px" alt="Logo UNC y Prosecretaría" />
    </footer>
</div>  

</template>

<script>
    import { HttpFRONT } from './share.js'
    import { mapGetters } from 'vuex';
    export default {
        data () {
            return {
                dataMenu: '',
                menuItems: [
                // { title: "CONTACTO", href: "#seccionContacto" },
                { title: "PREGUNTAS FRECUENTES", href: "#preguntasFrecuentes" }
                /* { title: "SERVICIOS QUE OFRECE", href: "#servicesSection" } */
                ],
                publicPath: process.env.BASE_URL
            }
        },
        computed: {
            ...mapGetters([
                'configApp',
             ]),
            isInitStatus: function () {
                return this.$store.state.isInitStatus;
            },
            currentUser: function () {
                return this.$store.state.currentUser;
            }
        },
        created() {
            this.$store.dispatch('InitStatus');
            this.getMenu();
        },
        methods: {
            usersProfile() {
                //location.href = configApp.urlProfile;
                let self = this;
           
                HttpFRONT.get(`miuncurl`)
                    .then(response => {
                        if (response.status === 200) {
                            console.log("URL MIUNC:",response.data.url)
                            location.href = response.data.url;
                        } 
                    })
                    .catch(error => {
                        let status = error.response.status;
                        let message =
                        (error.response && error.response.data) ||
                        "Servicio temporalmente no disponible";

                            switch (status) {
                            case 401:
                                swal({
                                title: "Acceso denegado",
                                text: "No posee permisos para acceder a este servicio.",
                                type: "warning"
                                });
                                break;
                            case 500:
                                swal({
                                title: "Algo salió mal",
                                text: message,
                                type: "warning"
                                });
                                break;
                            default:
                                swal({
                                title: "Datos no guardados",
                                text: message,
                                type: "warning"
                                });
                                break;
                            }
                        })
                        .finally(() => {
                            //self.isInitializing = false;
                        });
            },
            uncEmail() {
                location.href = this.configApp.urlUncEmail
            },
            principal() {
                this.$router.push({ path: "/" });
            }, 
            getMenu() {

                let self = this;

                self.dataMenu = [
                    {
                        title: "¿Qué es usuarios UNC?",
                        icon: "zmdi zmdi-info",
                        route: "#primerSeccion"         
                    },
                    {
                        title: "Contacto",
                        icon: "zmdi zmdi-phone",
                        route: "#seccionContacto"
                    }
                ];


            }
        }
    } 

</script>

<style lang="scss">

    #sidebar .profile-menu > a {
        background: url(./assets/img/unc-landing.png) margin  !important;
        //background: none !important;
        //background-color: #EDECEC !important;
    }

    #sidebar .profile-menu > a .profile-info {
        padding: 0px !important;
    }

    .navBar-title {
        font-family: Reforma2018 !important;
        font-weight: 700 !important;
    }

    .navBar-subtitle {
        font-family: Reforma2018;
        font-weight: 700;
        font-size: 16px;
        color: white;
    }

    .navBar-subtitle-2 {
        font-family: Reforma2018 !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        color: white !important;
    }

    .header {
        color: white !important;
    }

    #menu-trigger {
        width: 45px !important;
    }
    #menu-trigger:before {
        height: 0px !important;
    }

    @media (max-width: 1024px) {
        .hidden-items {
            display: none !important;
        }
        .header-title {
            margin-left: 0px !important;
            cursor: pointer;
        }
        .btn-collapse {
            padding-top: 2px;
        }
    }

    @media (min-width: 1024px) {
        .btn-collapse {
            display: none !important;
            margin-left: 45px !important;
        }
          .header-title {
            margin-left: 43px !important;
            cursor: pointer;
        }
    }

    .btn-crear-usuario-header {
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75) !important;
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75) !important;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75) !important; 
        font-family: Reforma2018;
        font-style: normal;
        font-weight: 700;
        text-rendering: optimizeLegibility;
        font-size: 16px;
        background-color: #9f0391 !important; /*53284F!important*/
        color: white;
        border-color: #9f0391;
        border-radius: 20px!important;
        border: none;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        padding: 5px 15px;
        margin: 3px 5px 0 10px !important;
        box-shadow: none;
    }

    .btn-miunc-header {
        font-family: Reforma2018;
        font-style: normal;
        font-weight: 700;
        text-rendering: optimizeLegibility;
        font-size: 16px;
        background-color: #3eb6b8 !important; /*53284F!important*/
        color: white;
        border-color: #3eb6b8;
        border-radius: 2px;
        border: none;
        padding: 5px 15px;
        margin: 3px 5px 0 10px !important;
        box-shadow: none;
    }

    .boton-perfil {
        margin-left: 500px;
    }

    #sidebar .main-menu > li > a {
        padding: 10px 20px 14px 52px !important;
    }


</style>