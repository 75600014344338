<template>
<div>
    <div v-if="isInitializing" class="loading">
        <unc-loader></unc-loader>
    </div>
            
    <div v-else class="row animated fadeIn">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card animated fadeIn">
                <div class="card-header ch-alt">
                    <h2>{{ titulo }}
<!--                         <small>
                            {{ this.perfil === this.selectedPersonRol.name ? 'Por favor, indicanos si la siguiente información es correcta. En caso de no serlo, comunicate con tu administrador.'
                            : `Por favor, indicanos si la siguiente información es correcta. En caso de no serlo, comunicate a ${emailHelpdesk}.` }}
                        </small> -->
                        <!-- <small>
                            {{ (this.currentUser && this.currentUser.perfil && this.currentUser.perfil.includes(this.selectedPersonRol.name)) ? 'Por favor, indicanos si la siguiente información es correcta. En caso de no serlo, comunicate con tu administrador.'
                            : `Por favor, indicanos si la siguiente información es correcta. En caso de no serlo, comunicate a ${emailHelpdesk}.` }}
                        </small> -->
                        <small>
                            <p>Verificá y completá tus datos y ante cualquier duda enviá un correo electrónico al Despacho de Alumnos de tu facultad, o al administrador de tu dependencia.</p>
                        </small>
                    </h2>
                </div>
                <div class="card-body card-padding">
                    <form @submit.prevent="validateBeforeSubmit">  

                    <template v-if=" this.isManualRegister"> <!-- LA PERSONA NO EXISTE EN YULI NI EN GUARANI, ES UN AUTOREGISTRO -->
                        
                        <div class="form-group-no-edit" :class="{'has-error': errors.has('identityNumber')}">
                            <div class="fg-line">
                                <label>País:</label>&nbsp;<span>{{ searchIdentifier.countryName }}</span>
                            </div>
                            <small v-if="errors.has('countryCode')" class="help-block">{{ errors.first('countryCode') }}</small>
                        </div>                        

                        <div class="form-group-no-edit" :class="{'has-error': errors.has('identityNumber')}">
                            <div class="fg-line">
                                <label>Documento:</label>&nbsp;<span>{{ item.identityTypeCode.split('_')[1] }} {{ item.identityNumber }}</span>
                            </div>
                            <small v-if="errors.has('identityNumber')" class="help-block">{{ errors.first('identityNumber') }}</small>
                        </div>                        
                        
                        <div class="form-group-no-edit-last" :class="{'has-error': errors.has('identityNumber')}">
                            <div class="fg-line">
                                <label>Rol:</label>&nbsp;<span>{{ this.selectedPersonRol.name }} </span>
                            </div>
                            <small v-if="errors.has('identityNumber')" class="help-block">{{ errors.first('identityNumber') }}</small>
                        </div>  

                        <div class="form-group-no-edit-last" :class="{'has-error': errors.has('names')}">
                            <div class="fg-line">
                                <label>Nombres:</label>
                                <input v-model="item.names"  id="names" name="names" type="text" class="form-control" v-validate="'required|nombre_apellido'" placeholder="Ingresá tu nombre...">
                            </div>
                            <small v-if="errors.has('names')" class="help-block">{{ errors.first('names') }}</small>
                        </div>

                        <div class="form-group-no-edit-last" :class="{'has-error': errors.has('lastnames')}">
                            <div class="fg-line">
                                <label>Apellidos:</label>
                                <input v-model="item.lastNames" id="lastnames" name="lastnames" type="text" class="form-control" v-validate="'required|nombre_apellido'" placeholder="Ingresá tu apellido...">
                            </div>
                            <small v-if="errors.has('lastnames')" class="help-block">{{ errors.first('lastnames') }}</small>
                        </div>                        

                        <div class="form-group-no-edit-last" :class="{'has-error': errors.has('email')}">
                            <div class="fg-line">
                                <label>Correo Electrónico de Recuperación:</label>
                                <input v-model="item.email" id="email" name="email" v-validate="'required|email|isNotYuliEmail|alphanum_underscore'" type="text" class="form-control"  placeholder="Ingresá tu correo electrónico de recuperación...">
                            </div>
                            <small v-if="errors.has('email')" class="help-block">{{ errors.first('email') }}</small>
                                <p style="font-size:8pt"><i>Enviaremos un correo electrónico a esta dirección para completar tu registro. También será utilizado para recuperar tu contraseña.</i></p>
                        </div>
                    </template>  

                    <template v-else>  <!-- LA PERSONA VIENE DE GUARANI, PUEDE DARSE QUE LOS ROLES COINCIDAN O NO. -->

                        <template v-if="rolesCoinciden"> <!-- ROLES SI COINCIDEN -->

                            <div class="form-group-no-edit" :class="{'has-error': errors.has('identityNumber')}">
                                <div class="fg-line">
                                    <label>Nombres: </label>&nbsp;<span>{{ item.names }}</span>
                                </div>
                                <small v-if="errors.has('names')" class="help-block">{{ errors.first('names') }}</small>
                            </div>

                            <div class="form-group-no-edit" :class="{'has-error': errors.has('identityNumber')}">
                                <div class="fg-line">
                                    <label>Apellidos: </label>&nbsp;<span>{{ item.lastNames }}</span>
                                </div>
                                <small v-if="errors.has('lastNames')" class="help-block">{{ errors.first('lastNames') }}</small>
                            </div>                                          

                            <div class="form-group-no-edit" :class="{'has-error': errors.has('identityNumber')}">
                                <div class="fg-line">
                                    <label>País: </label>&nbsp;<span>{{ searchIdentifier.countryName }}</span>
                                </div>
                                <small v-if="errors.has('countryCode')" class="help-block">{{ errors.first('countryCode') }}</small>
                            </div> 

                            <div class="form-group-no-edit" :class="{'has-error': errors.has('identityNumber')}">
                                <div class="fg-line">
                                    <label>Documento: </label>&nbsp;<span>{{ item.identityTypeCode.split('_')[1] }} {{ item.identityNumber }}</span>
                                </div>
                                <small v-if="errors.has('identityNumber')" class="help-block">{{ errors.first('identityNumber') }}</small>
                            </div>

                            <div class="form-group-no-edit" :class="{'has-error': errors.has('identityNumber')}">
                                <div class="fg-line">
                                    <label>Rol: </label>&nbsp;<span>{{ this.selectedPersonRol.name }} </span>
                                </div>
                                <small v-if="errors.has('identityNumber')" class="help-block">{{ errors.first('identityNumber') }}</small>
                            </div>  

                            <div class="form-group" :class="{'has-error': errors.has('email')}">
                                <div class="fg-line">
                                    <label>Correo Electrónico de Recuperación:</label>
                                    <br><span>{{ maskedEmail(item.email) }}</span>
                                </div>
                                <br>
                                <small v-if="errors.has('email')" class="help-block">{{ errors.first('email') }}</small>
                                    <p v-if="!isDisabled" style="font-size:8pt"><i>Este correo proviene de Guaraní. Enviaremos un correo a esta dirección para completar tu registro. También será utilizado para recuperar tu contraseña. Si por algún motivo quieres cambiar este correo, deberás realizarlo primero desde Autogestión Guaraní.</i></p>
                                    <p v-else class="warning" style="font-size:8pt"><i>Este correo proviene de Guaraní. Su correo de recuperación no puede ser un correo @unc.edu.ar o @mi.unc.edu.ar, por favor, cambie su correo de recuperación desde Guaraní.</i></p>
                            </div>

                        </template>

                        <template v-else> <!-- ROLES NO COINCIDEN -->
                            <div class="form-group-no-edit" :class="{'has-error': errors.has('identityNumber')}">
                                <div class="fg-line">
                                    <label>País:</label>&nbsp;<span>{{ searchIdentifier.countryName }}</span>
                                </div>
                                <small v-if="errors.has('countryCode')" class="help-block">{{ errors.first('countryCode') }}</small>
                            </div>                             

                            <div class="form-group-no-edit" :class="{'has-error': errors.has('identityNumber')}">
                                <div class="fg-line">
                                    <label>Documento:</label>&nbsp;<span>{{ item.identityTypeCode.split('_')[1] }} {{ item.identityNumber }}</span>
                                </div>
                                <small v-if="errors.has('identityNumber')" class="help-block">{{ errors.first('identityNumber') }}</small>
                            </div>

                            <div class="form-group-no-edit-last" :class="{'has-error': errors.has('identityNumber')}">
                                <div class="fg-line">
                                    <label>Rol:</label>&nbsp;<span>{{ this.selectedPersonRol.name }} </span>
                                </div>
                                <small v-if="errors.has('identityNumber')" class="help-block">{{ errors.first('identityNumber') }}</small>
                            </div>  

                            <div class="form-group-no-edit-last" :class="{'has-error': errors.has('names')}">
                                <div class="fg-line">
                                    <label>Nombres:</label>
                                    <input v-model="item.names" id="names" name="names" type="text" class="form-control" v-validate="'required|nombre_apellido'" placeholder="Ingresá tu nombre...">
                                </div>
                                <small v-if="errors.has('names')" class="help-block">{{ errors.first('names') }}</small>
                            </div>

                            <div class="form-group-no-edit-last" :class="{'has-error': errors.has('lastnames')}">
                                <div class="fg-line">
                                    <label>Apellidos:</label>
                                    <input v-model="item.lastNames" id="lastnames" name="lastnames" type="text" class="form-control" v-validate="'required|nombre_apellido'" placeholder="Ingresá tu apellido...">
                                </div>
                                <small v-if="errors.has('lastNames')" class="help-block">{{ errors.first('lastNames') }}</small>
                            </div>                        

                            <div class="form-group-no-edit-last" :class="{'has-error': errors.has('email')}">
                                <div class="fg-line">
                                    <label>Correo Electrónico de Recuperación:</label>
                                    <input v-model="item.email" id="email" name="email" v-validate="'required|email|isNotYuliEmail'" type="text" class="form-control"  placeholder="Ingresá tu correo electrónico de recuperación...">
                                </div>
                                <small v-if="errors.has('email')" class="help-block">{{ errors.first('email') }}</small>
                                    <p style="font-size:8pt"><i>Enviaremos un correo electrónico a esta dirección para completar tu registro. También será utilizado para recuperar tu contraseña.</i></p>
                            </div>

<!--                             <div>
                                <label>subir archivo</label>
                            </div> -->
                        </template>

                    </template>

                        <div class="text-right">
                            <button @click.prevent="goBack()" type="button" class="btn btn-link m-t-10 waves-effect">Volver</button>
                            <button v-if="!isDisabled" type="submit" class='btn btn-primary m-t-10 waves-effect'>Siguiente</button>
                            <button v-else type="button" @click.prevent="showAlert()" class='btn btn-primary btn-inactive m-t-10 waves-effect'>Siguiente</button>
                        </div>                  
                    </form>
            
                </div> 
            </div>

        </div>
    </div>

</div>
</template>

<script>
    import { HttpGEO, swal, HttpFRONT } from '../../share.js';
    import { mapState } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                titulo: 'DATOS PERSONALES',
                isInitializing: true,
                item: {
                    id: '',
                    names: '',
                    lastNames: '',
                    countryCode: '',
                    identityTypeId: '',
                    identityTypeCode: '',
                    identityTypeName: '',
                    identityNumber: '',
                    email: '',
                    selectedRole: '',
                    profiles: '',
                    domain: '',
                    origin: '',
                    orgUnits: '',
                },
                allIdentityTypes: null,
                showConfirmation: false,
                emailHelpdesk: '',
                countryName: '',
                contactUsMessage: "",
                maskEmailRecuperation: null 
            }
        },
        computed: {
            isManualRegister: function() {
                return this.currentUser ? false : true;
            },
            ...mapState([
                'selectedPersonRol',
                'currentUser',
                'searchIdentifier',
                'identityTypes',
                'optionsCountries'
            ]),
            ...mapGetters([
                'yuliPersonId',
                'configApp',
                'invalidDomains',
            ]),
            rolesCoinciden: function() {
                const profiles = this.currentUser?.profiles ?? [];
                return profiles.length === 1 && profiles.includes(this.selectedPersonRol.code);
            },
            isDisabled() {
                let domains = this.invalidDomains.split(',')
                domains = domains.filter(e => !e.includes('.')).map(e => `${e}.`)
                if(!this.isManualRegister && this.rolesCoinciden) {
                    return new RegExp(`.*@((${domains.join('|')}))?unc.edu.ar$`).test(this.item.email)
                }
                return false

            },
            isCurrentUserUpdating: function() {
                if(this.currentUser && this.currentUser.status){
                    return (this.currentUser.status === 'P' || this.currentUser.status === 'PE' || this.currentUser.status === 'PD') ? true : false;
                }else{
                    return false;
                }
            }
        },
        mounted() {
            this.emailHelpdesk = this.configApp.emailHelpdesk;
            this.contactUsMessage = this.configApp.contactUsMessage || "";
            if (!this.isManualRegister && !this.rolesCoinciden) {
                this.item.email = '';
            }
        },
        created() {
            this.getData();

        },
        methods: {
            titleCase(str) {
                    return str.toLowerCase().split(' ').map(function(word) {
                        return (word.charAt(0).toUpperCase() + word.slice(1));
                    }).join(' ');
            },
            maskedEmail: function(email) {    
                if (email) {
                    let emailRecName = email.split('@')[0];
                    let emailRecDomain = email.split('@')[1];
                    let firstPartName = emailRecName.slice(0,3);
                    let secondPartName = '*****';
                    let thirdPartName = emailRecName.substr(emailRecName.length -3);
                    this.maskEmailRecuperation = firstPartName + secondPartName + thirdPartName + '@' + emailRecDomain;
                }
                return this.maskEmailRecuperation;
            },            
            getData() {
                let self = this;

                if (self.isManualRegister) { // AUTOREGISTRO (No trae un usuario)
                    self.item.id = '';
                    self.item.names = '';
                    self.item.lastNames = '';
                    self.item.countryCode = self.searchIdentifier.countryCode;
                    self.item.identityTypeId = self.searchIdentifier._id;
                    self.item.identityTypeCode = self.searchIdentifier.code;
                    self.item.identityTypeName = self.searchIdentifier.name;
                    self.item.identityNumber = self.searchIdentifier.number;
                    self.item.email = '';
                    self.item.role = this.selectedPersonRol.name;
                    self.item.domain = this.selectedPersonRol.domain; // FALTA QUE LA API RECIBA ESTE VALOR
                    self.item.idRol = self.selectedPersonRol._id;
                    self.item.origin = 'YULI';  // 
                    self.item.idpersonaguarani = '';

                    self.isInitializing = false;

                } else {                       //(Trae un usuario)
                    self.item.origin            = this.rolesCoinciden ? 'GUARANI' : 'YULI';
                    self.item.id                = self.currentUser.idPersonaYuli;
                    self.item.names             = self.currentUser.names;
                    self.item.lastNames         = self.currentUser.lastNames;
                    self.item.countryCode       = self.currentUser.identities[0].identityType;
                    self.item.identityTypeId    = self.currentUser.identities[0].identityType._id;
                    self.item.identityTypeCode  = self.currentUser.identities[0].identityType;
                    self.item.identityTypeName  = self.currentUser.identities[0].identityType.name;
                    self.item.identityNumber    = self.currentUser.identities[0].identityNumber;
                    self.item.email             = self.currentUser.emailRecuperation;
                    self.item.role              = self.selectedPersonRol.name;
                    self.item.roleCode          = self.selectedPersonRol.code;
                    self.item.domain            = self.selectedPersonRol.domain; // FALTA QUE LA API RECIBA ESTE VALOR
                    self.item.idRol             = self.selectedPersonRol._id;
                    self.item.idpersonaguarani  = self.currentUser.idPersonaGuarani;
                    self.item.orgUnits          = self.currentUser.orgUnits;
                    self.isInitializing         = false;
                }


            },
            showAlert(){
                swal({
                    title: "Oops!",
                    text: "Tu correo de recuperación no puede ser un correo @unc.edu.ar o @mi.unc.edu.ar. Por favor, cambia tu correo de recuperación desde Guaraní.",
                    type: "warning"
                    });
            },
            getCountries(){
                return HttpGEO.get('countries');
            },
            getIdentityTypes(){
                return HttpGEO.get('/identity_types')
            },
            getPersonOrgUnits(orgUnits) {
                return orgUnits.map( ou => {
                    return {
                        orgUnit: ou.orgUnit,
                        uncProfiles: [this.item.roleCode],
                    };
                });
            },
            validateBeforeSubmit() {
                this.$validator.validateAll().then(() => {
                    if (!this.errors.any()) {
                        this.save();
                    }
                }).catch(() => {
                    swal({title: 'Algo salió mal', text: 'Tus datos no fueron registrados.', type: 'warning' });
                });
            
            },
            save() {
                let self = this;
                self.isInitializing = true;

                
                if (self.isCurrentUserUpdating) {
                    
                    let personToSend = {
                        names: this.titleCase((self.item.names).trim()),
                        lastNames: this.titleCase((self.item.lastNames).trim()),
                        mainEmail: self.item.email.toLowerCase(),
                        identities: [
                        {
                            identityNumber: self.item.identityNumber.replace(/ /g,""),
                            identityType: self.item.identityTypeCode
                        }
                        ],
                        profile: self.item.idRol,
                        origin: self.item.origin,
                        idpersonaguarani: self.item.idpersonaguarani,
                        idpersonayuli : self.item.id
                        
                    };
                    if(self.rolesCoinciden && self.item.orgUnits?.length !== 0) {
                        const userProfiles = new Set(self.item.orgUnits.flatMap( ou => ou.profiles ))
                        if(userProfiles.size === 1) {
                            personToSend.personOrgUnits = this.getPersonOrgUnits(self.item.orgUnits);
                        }
                    }

                    
                    HttpFRONT.patch(`personas/${personToSend.idpersonayuli}/personUpdate`, personToSend)
                    .then(response => {
                        if (response.status === 201 || response.status === 200) {
                            // Success
                            swal({title:  '', text: `Enviamos un correo electrónico a ${self.maskedEmail(self.item.email)}. Ingresá a tu correo y sigue las instrucciones para continuar con el registro de tu usuario`, type: 'success' });
                            this.$router.push('/');
                        }
                    }).catch(error => {
                        let status = error.response.status;
                        
                        
                        if (status === 401) {
                            swal({title: 'Acceso Denegado', text: 'No posee permisos para acceder a este servicio.', type: 'warning' });
                        } else if (status === 409) {
                            swal({title: 'Algo salió mal', text: error.response.data.message?error.response.data.message :'Comunicate con el Despacho de Alumnos de tu facultad o el Administrador de tu dependencia.', type: 'warning' });
                        } else {
                            swal({title: 'Algo salió mal', text: 'Servicio temporalmente no disponible.', type: 'warning' });
                        }
                    }).finally(() => {
                        self.isInitializing = false;
                    });  
                    
                } else {
                    let personToSend = {
                        names: this.titleCase((self.item.names).trim()),
                        lastNames: this.titleCase((self.item.lastNames).trim()),
                        mainEmail: self.item.email.toLowerCase(),
                        identities: [
                        {
                            identityNumber: self.item.identityNumber.replace(/ /g,""),
                            identityType: self.item.identityTypeCode
                        }
                        ],
                        profile: self.item.idRol,
                        origin: self.item.origin,
                        idpersonaguarani: self.item.idpersonaguarani
                        
                    };

                    if(self.rolesCoinciden && self.item.orgUnits?.length !== 0) {
                        const userProfiles = new Set(self.item.orgUnits.flatMap( ou => ou.profiles ))
                        if(userProfiles.size === 1) {
                            personToSend.personOrgUnits = this.getPersonOrgUnits(self.item.orgUnits);
                        }
                    }
                    
                    if (!self.maskEmailRecuperation) self.maskEmailRecuperation = personToSend.mainEmail;
                    HttpFRONT.post('personas', personToSend)
                    .then(response => {
                        if (response.status === 201 || response.status === 200) {
                            // Success
                            swal({title: '', text: `Enviamos un correo electrónico a ${self.maskedEmail(self.item.email)}. Ingresá a tu correo y seguí las instrucciones para continuar con el registro del usuario`, type: 'success' });
                            self.$router.push('/');
                        }
                    }).catch(error => {
                        let status = error.response.status;
                        let message = error.response && error.response.data || 'Servicio temporalmente no disponible';
                        switch (status) {
                            case 401:
                                swal({title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', type: 'warning' });
                                break;
                            case 409:
                                swal({title: 'Datos no guardados', text: error.response.data?error.response.data:'Hubo un conflicto con tus datos. Tu correo o número de Documento ya se encuentran asignados a otra persona.', type: 'warning' });
                                break;
                            case 500:
                                swal({title: 'Algo salió mal', text: 'Servicio no disponible. Intentá más tarde.', type: 'warning' });
                                break;
                            default:
                                swal({title: 'Datos no guardados', text: 'Tus datos no se pudieron guardar. Intentá más tarde.', type: 'warning' });
                                break;
                        }

                        // if (status == 401) {
                        //     swal({title: 'Acceso Denegado', text: 'No posee permisos para acceder a este servicio.', type: 'warning' });
                        // } else if (status === 409) {
                        //     swal({title: 'Usuario existente', text: `Ya existe un usuario registrado con este número de documento. Por cualquier consulta envíanos un correo electrónico a la dirección ${this.emailHelpdesk} comentándonos de tu situación.`, type: 'warning' });
                        // } else {
                        //     swal({title: 'Algo salió mal', text: message, type: 'warning' });
                        // }
                    
                    }).finally(() => {
                        self.isInitializing = false;
                    });  
                
                }
            }, 
            goBack(){
                this.$store.commit('setCurrentComponent','register-search');
            }
        }
    }
</script>

<style lang="scss">

.warning{
    color: red;
}

.btn-inactive{
    opacity: 0.65;
    cursor: not-allowed !important;
}

</style>
